import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BannerH4 from '../components/share/banner/bannerH4'
import BannerH6 from '../components/share/banner/bannerH6'
import BrandBoxes from '../components/share/brandBoxes'
import Button from '../components/share/button'
import TypeFormButton from '../components/share/typeFormButton'
import FooterBox from '../components/share/footerBox'
import ContentWrapper from '../components/share/contentWrapper'
import FeatureBox from '../components/share/featureBox'
import GreyBackground from '../components/share/greyBackground'
import ImageBackground from '../components/share/imageBackground'
import IntroRow from '../components/share/introRow'
import IntroVideo from '../components/share/introVideo'
import Layout from '../components/layout'
import Quote from '../components/share/quote'
import ProductBoxes from '../components/share/productBoxes'
import ServiceBoxes from '../components/share/serviceBoxes'
import SpeakToTeam from '../components/share/speakToTeam'

import customMedia from '../utils/customMedia'

const query = graphql`
  query {
    indexBackground: file(relativePath: { eq: "index/indexHeader.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sellMore: file(relativePath: { eq: "index/sell_more.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    toolKit: file(relativePath: { eq: "index/toolkit.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    proposals: file(relativePath: { eq: "index/proposals.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    phone: file(relativePath: { eq: "share/phone.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    management: file(relativePath: { eq: "index/management.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    happierVendors: file(relativePath: { eq: "index/happier_vendors.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    betterSupport: file(relativePath: { eq: "index/better_support.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    easyMarketing: file(relativePath: { eq: "index/easy_marketing.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mathewbourn: file(relativePath: { eq: "brandAgentIcon/mathewbourn.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leading: file(relativePath: { eq: "index/leading.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    template: file(relativePath: { eq: "index/template.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    supportBackground: file(
      relativePath: { eq: "share/supportBackground.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    supportPhone: file(relativePath: { eq: "index/supportPhone.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mcconnellBourn: file(
      relativePath: { eq: "brandLogos/mcconnellBourn.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class IndexPage extends Component {
  _scrollToSection = title => {
    this[title].scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.indexBackground.childImageSharp.fluid}
                skew
              >
                <ContentWrapper style={{ flexWrap: 'nowrap' }}>
                  <HeaderTitle>
                    Built for ambitious real estate agencies,{' '}
                    <strong>
                      <i>just like yours</i>
                    </strong>
                  </HeaderTitle>

                  <HeaderBody>
                    Powerful, fast and easy to use,{' '}
                    <strong>Real Estate Marketing platform</strong> that powers{' '}
                    <strong>
                      <i>your agency</i>
                    </strong>{' '}
                    and <strong>your personal marketing</strong> campaigns from
                    digital and print providers Australia wide.
                    <br />
                    <br />
                    Built for professional agencies, just like yours.
                  </HeaderBody>

                  <HeaderLink onClick={() => this._scrollToSection('video')}>
                    Discover why over 650+ agencies have made the switch{' '}
                  </HeaderLink>

                  <Button
                    text="REQUEST A DEMO"
                    onClick={() => this._scrollToSection('requestDemo')}
                  />
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper>
                <FlexColumn>
                  <FirstImage fluid={data.sellMore.childImageSharp.fluid} />

                  <BannerH4 style={{ marginBottom: 0, marginTop: 30 }}>
                    Sell <strong>more properties</strong> with{' '}
                    <strong>fewer staff</strong>, in <strong>less time</strong>
                  </BannerH4>

                  <BannerH6>
                    Powerful, secure and innovative real estate technology you
                    can trust to power your business.
                  </BannerH6>
                </FlexColumn>
              </ContentWrapper>

              <ContentWrapper contentRef={el => (this.video = el)}>
                <FlexColumn>
                  <Text>
                    How it works <Icon icon={['fal', 'arrow-down']} />
                  </Text>

                  <IntroVideo />
                </FlexColumn>
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  image={data.toolKit.childImageSharp.fluid}
                  title="Your new complete toolkit. A swiss army knife for Real Estate businesses just like yours."
                  link="/case-studies"
                  linkText="So who else is using it?"
                  imgHeight="300px"
                  content={
                    <Fragment>
                      <Paragraph>
                        You need the most powerful and easy to use platform
                        available whether you’re a single agent looking to grow
                        your personal brand or a full service agency with
                        hundreds of agents.
                      </Paragraph>

                      <Paragraph>
                        Realhub caters to any sized real estate agency or brand
                        with user-first design inclusive of intensive user
                        testing and feedback.
                      </Paragraph>

                      <Paragraph>
                        We’re fast moving and constantly evolving with the
                        volatile future of the real estate market while offering
                        industry leading support and service. Keeping you in
                        front.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <GreyBackground skew>
                <ContentWrapper
                  direction="row"
                  style={{ alignItems: 'center' }}
                >
                  <FeatureBox
                    image={data.proposals.childImageSharp.fluid}
                    title="Proposals"
                    subTitle="Find leads, win more listings"
                    content="Use the industries most advanced reporting and digital proposal software on the market to wow sellers."
                    link="/proposals"
                  />
                  <FeatureBox
                    image={data.phone.childImageSharp.fluid}
                    title="Advertising"
                    subTitle="Marketing Campaign Manager"
                    content="Tools that make you more efficient, allowing you to sell more houses with less overheads and resources."
                    link="/marketing"
                  />
                  <FeatureBox
                    image={data.management.childImageSharp.fluid}
                    title="Management"
                    subTitle="Business Management Tools"
                    content="Keep complete end-to-end oversight across your business and staff’s marketing and productivity."
                    link="/campaigns"
                  />
                </ContentWrapper>
              </GreyBackground>

              <ContentWrapper>
                <Quote
                  agentImage={data.mathewbourn.childImageSharp.fluid}
                  desc={`"We've grown, we've developed and we've evolved into a bigger and better agency than what we were last year, supported by the team at Realhub"`}
                  agencyName="McconnellBourn"
                  title="Principal & Director"
                  agentName="Matthew Bourn"
                />
              </ContentWrapper>

              <ImageBackground
                background={data.supportBackground.childImageSharp.fluid}
                height="700px"
                opacity="0.5"
              >
                <ContentWrapper>
                  <IntroRow
                    title="Seriously Talented, Fast Support"
                    linkText="Why are we different?"
                    link="/support"
                    content={
                      <Fragment>
                        <Paragraph>
                          Tech savvy? Our platform is the easiest to use in the
                          industry. Technologically illiterate? That’s ok too,
                          we’re just a call, message or email away with
                          knowledgable support that can help you when you
                          need it.
                        </Paragraph>

                        <Paragraph>
                          {' '}
                          Join our team today and experience the difference.
                        </Paragraph>

                        <Paragraph>
                          <strong
                          >{`Our average response time is < 1 Hour`}</strong>
                        </Paragraph>
                      </Fragment>
                    }
                  />
                  <SupportPhone
                    fluid={data.supportPhone.childImageSharp.fluid}
                    style={{ position: 'absolute' }}
                  />
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper style={{ marginTop: '150px' }}>
                <IntroRow
                  image={data.leading.childImageSharp.fluid}
                  title="Industry leading design and usability"
                  link="https://blog.realhub.com.au/"
                  linkText="Read more on our blog"
                  content={
                    <Fragment>
                      <Paragraph>
                        Our focus on the latest ground-breaking technology and
                        staying up to date with the world’s leading technology
                        companies in the way we design, research, develop and
                        release our products means you’re always getting the
                        latest techniques to improve your daily work.
                      </Paragraph>

                      <Paragraph>
                        Built on a platform used by the leaders of the
                        technology industry like Facebook, Netflix, Instagram,
                        Dropbox and even Australia’s largest technology start up
                        Atlassian, bringing all those huge benefits over to you.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  reverse
                  image={data.template.childImageSharp.fluid}
                  title="Unlimited fully unique branded templates"
                  link="/marketing"
                  linkText="Read more about marketing on Realhub"
                  content={
                    <Fragment>
                      <Paragraph>
                        There’s no pre-assigned generic one size fits all
                        templates on our system. Every artwork you create will
                        be 100% unique to your brand, and instantly accessible
                        through our easy to use ordering platform.
                      </Paragraph>

                      <Paragraph>
                        Your brand is your baby, don’t let anyone corrupt it’s
                        beauty and prominence in your marketing. With Realhub
                        your brand will be protected and it’s appearance on
                        final delivery will always be consistent and compliant
                        to brand guidelines.
                      </Paragraph>
                      <Paragraph>
                        Our artwork platform is the easiest to use in the
                        industry, it’s no good having beautiful marketing if
                        your marketing team can’t figure out how to get the best
                        out of them with templates so complicated no one could
                        figure out, so we’ve made our artwork builder easy
                        enough for anyone to learn.
                      </Paragraph>
                      <Paragraph>
                        No more back and forward proofing with designers, and no
                        more expensive design software.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    All of <strong>your campaigns</strong>, and all of{' '}
                    <strong>your marketing needs.</strong>
                  </BannerH4>

                  <BannerH6 style={{ marginBottom: 0 }}>
                    Our products power your entire real estate marketing
                    efforts.
                  </BannerH6>
                </FlexColumn>
              </ContentWrapper>

              <ContentWrapper>
                <ProductBoxes />
              </ContentWrapper>

              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    Get <strong>more services</strong> in one place, with a
                    <strong> professional marketing platform </strong>
                    that enables growth.
                  </BannerH4>

                  <BannerH6 style={{ marginBottom: 0 }}>
                    The power to choose from any real estate supplier for all
                    your services.
                  </BannerH6>
                </FlexColumn>
              </ContentWrapper>

              <ContentWrapper>
                <ServiceBoxes />
              </ContentWrapper>

              <GreyBackground skew>
                <ContentWrapper
                  direction="row"
                  style={{ alignItems: 'center' }}
                >
                  <FeatureBox
                    image={data.easyMarketing.childImageSharp.fluid}
                    contentStyle={{ height: '120px' }}
                    title="Easier Marketing"
                    subTitle="Powerful, Intuitive, Easy"
                    content="Marketing and design software that does the heavy lifting and stays out of your way. Craft masterful marketing material faster than ever."
                    link="/marketing"
                  />
                  <FeatureBox
                    image={data.happierVendors.childImageSharp.fluid}
                    contentStyle={{ height: '120px' }}
                    title="Happier Vendors"
                    subTitle="Professional Digital Agent"
                    content="Our tools like the vendor dashboard and mobile artwork and photography proofing are designed to make you more effective and efficient agent in the eyes of your vendors."
                    link="/mobile-agent"
                  />

                  <FeatureBox
                    image={data.betterSupport.childImageSharp.fluid}
                    contentStyle={{ height: '120px' }}
                    title="Better Support"
                    subTitle="Email, Phone and Chat Support"
                    content="Our support is second to none. We pride ourselves on our experienced support team and their ability to directly help and act upon your issues."
                    link="/support"
                  />
                </ContentWrapper>
              </GreyBackground>

              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    Our <strong>loyal team</strong> of{' '}
                    <strong>Australia’s leading</strong> real estate agencies,
                    working <strong>hand in hand</strong> to help build the{' '}
                    <strong>future of real estate.</strong>
                  </BannerH4>
                  <BannerH6 style={{ marginBottom: 0 }}>
                    Input from the largest and smallest real estate businesses
                    in Australia is helping to build the most effective platform
                    for professional agents.
                  </BannerH6>
                </FlexColumn>
              </ContentWrapper>
              <ContentWrapper>
                <BrandBoxes />
              </ContentWrapper>
              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    We’re already <strong>powering your competition.</strong>
                  </BannerH4>
                  <BannerH6>
                    Don’t get <strong>left behind.</strong>
                  </BannerH6>
                  <TypeFormButton />
                </FlexColumn>
              </ContentWrapper>

              <ContentWrapper contentRef={el => (this.requestDemo = el)}>
                <SpeakToTeam />
              </ContentWrapper>
              <ContentWrapper direction="row">
                <FooterBox name="migration" />

                <FooterBox name="feetUp" />
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default IndexPage

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 500px;
    font-size: 53px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 1.5;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 450px;
  `};
`

const HeaderLink = styled.a`
  font-family: Caveat;
  color: #02b7e2;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  cursor: pointer;

  ${customMedia.greaterThan('desktop')`
    margin-bottom: 10px;
    font-size: 18px;
  `};
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 14px;
  margin-left: 5px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Text = styled.div`
  font-family: Caveat;
  font-size: 20px;
  text-align: center;
  color: #02b7e2;
  letter-spacing: 1px;
  margin: 20px 0 5px 0;

  ${customMedia.greaterThan('desktop')`
    font-size: 18px;
  `};
`

const SupportPhone = styled(Img)`
  position: absolute;
  overflow: hidden;
  width: 200px;
  height: 200px;
  bottom: -192px;
  right: -10px;

  ${customMedia.between('mobile', 'tablet')`
    width: 350px;
    height: 350px;
    bottom: -300px;
    right: -50px;
  `};

  ${customMedia.greaterThan('desktop')`
    width: 350px;
    height: 350px;
    bottom: -300px;
    right: -150px;
  `};
`

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const FirstImage = styled(Img)`
  width: 100%;
  margin: auto;

  ${customMedia.greaterThan('mobile')`
    width: 75%;
  `};
`

const ConversationText = styled.img`
  width: 70%;
  margin: auto;

  ${customMedia.between('mobile', 'tablet')`
     width: 70%;
  `};

  ${customMedia.greaterThan('mobile')`
    width: 50%;
  `};
`
