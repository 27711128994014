import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import VideoLightBox from './videoLightBox'

import customMedia from '../../utils/customMedia'

const FeatureBox = props => {
  const {
    content,
    contentStyle,
    detailLinks,
    featureText,
    image,
    imageRadius,
    imgLength,
    link,
    subTitle,
    title,
  } = props

  return (
    <Wrapper>
      {image && (
        <FeatureImg
          fluid={image}
          imgStyle={{ objectFit: 'contain' }}
          imgLength={imgLength}
          imageRadius={imageRadius}
        />
      )}

      {featureText && <FeatureText>{featureText}</FeatureText>}

      <Details>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
        <Content style={contentStyle}>{content}</Content>
        {link && (
          <MoreLink to={link}>
            Learn More{' '}
            <FontAwesomeIcon
              icon={['fal', 'arrow-right']}
              style={{ fontSize: '16px' }}
            />
          </MoreLink>
        )}
        {detailLinks && (
          <LinkBox>
            {detailLinks.map((detailLink, i) => {
              const { lightbox, link, text } = detailLink
              if (lightbox) {
                return (
                  <VideoLightBox
                    link={link}
                    text={text}
                    key={`video_link_${i}`}
                  />
                )
              } else {
                return (
                  <DetailLink
                    href={link}
                    key={`video_link_${i}`}
                    target="_blank"
                  >
                    {text + ' '}
                    <FontAwesomeIcon
                      icon={['fal', 'arrow-right']}
                      style={{ fontSize: '12px' }}
                    />
                  </DetailLink>
                )
              }
            })}
          </LinkBox>
        )}
      </Details>
    </Wrapper>
  )
}

FeatureBox.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contentStyle: PropTypes.object,
  detailLinks: PropTypes.array,
  featureText: PropTypes.string,
  image: PropTypes.object,
  imageRadius: PropTypes.bool,
  imgLength: PropTypes.string,
  link: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
}

export default FeatureBox

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    flex-direction: column;
    margin-bottom: 0;
    width: calc(100% / 3);
    padding: 0px 10px;
    text-align: center;
  `};
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 135px);

  ${customMedia.greaterThan('mobile')`
    width: 100%;
  `};
`

const FeatureImg = styled(Img)`
  width: ${props => props.imgLength || '100px'};
  height: ${props => props.imgLength || '100px'};
  border-radius: ${props => (props.imageRadius ? '100%' : '0')};
  margin-right: 30px;
  align-self: flex-start;

  ${customMedia.greaterThan('mobile')`
    margin-right: 0;
    align-self: center;
  `};
`

const FeatureText = styled.div`
  font-size: 18px;
  color: #02b7e2;
  width: 75px;
  margin-right: 30px;
  text-align: center;

  ${customMedia.between('mobile', 'tablet')`
    margin-right: 0;
    width: auto;
    font-size: 28px;
  `};

  ${customMedia.greaterThan('mobile')`
    margin-right: 0;
    width: auto;
    font-size: 32px;
  `};
`

const Title = styled.h6`
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 5px;

  ${customMedia.greaterThan('mobile')`
    margin: 10px 0;
  `};
`

const SubTitle = styled.div`
  font-size: 14px;
  color: #8c8c8e;
  margin: 15px 0;
`

const Content = styled.div`
  font-size: 14px;
  margin-bottom: 10px;

  ${customMedia.between('mobile', 'tablet')`
    padding: 0 10px;
  `};

  ${customMedia.greaterThan('desktop')`
    padding: 0 10px;
  `};
`
const MoreLink = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: #02b7e2;

  ${customMedia.greaterThan('desktop')`
    font-size: 16px;
  `};
`

const LinkBox = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 45px;
`

const DetailLink = styled.a`
  font-size: 12px;
  font-weight: 300;
  text-decoration: none;
  color: #02b7e2;

  ${customMedia.greaterThan('desktop')`
    font-size: 14px;
  `};
`
