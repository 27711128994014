import { servicesMedia } from '../components/share/mediaImport';

const servicesData = [
  {
    mediaHover: servicesMedia.serviceNewspaperHover,
    iconURL: servicesMedia.serviceNewspaperIcon,
    iconHover: servicesMedia.serviceNewspaperIconHover,
    iconSvg: servicesMedia.serviceNewspaperIcon,
    title: 'NEWSPAPER',
    shortName: 'newspaper',
    link: '/services',
    active: true,
  },
  {
    mediaHover: servicesMedia.serviceServicesHover,
    iconURL: servicesMedia.serviceServicesIcon,
    iconHover: servicesMedia.serviceServicesIconHover,
    iconSvg: servicesMedia.serviceServicesIcon,
    title: 'SERVICES',
    shortName: 'services',
    link: '/services',
    active: true,
  },
  {
    mediaHover: servicesMedia.serviceSocialMediaHover,
    iconURL: servicesMedia.serviceSocialMediaIcon,
    iconHover: servicesMedia.serviceSocialMediaIconHover,
    iconSvg: servicesMedia.serviceSocialMediaIcon,
    title: 'SOCIAL MEDIA',
    shortName: 'socialMedia',
    link: '/services',
    active: true,
  },
  {
    mediaHover: servicesMedia.serviceVideoHover,
    iconURL: servicesMedia.serviceVideoIcon,
    iconHover: servicesMedia.serviceVideoIconHover,
    iconSvg: servicesMedia.serviceVideoIcon,
    title: 'VIDEO',
    shortName: 'video',
    link: '/services',
    active: true,
  },
  {
    mediaHover: servicesMedia.serviceWebHover,
    iconURL: servicesMedia.serviceWebIcon,
    iconHover: servicesMedia.serviceWebIconHover,
    iconSvg: servicesMedia.serviceWebIcon,
    title: 'WEB',
    shortName: 'web',
    link: '/services',
    active: true,
  },
  {
    mediaHover: servicesMedia.servicePrintHover,
    iconURL: servicesMedia.servicePrintIcon,
    iconHover: servicesMedia.servicePrintIconHover,
    iconSvg: servicesMedia.servicePrintIcon,
    title: 'PRINT',
    shortName: 'print',
    link: '/services',
    active: true,
  },
  {
    mediaHover: servicesMedia.servicePhotographyHover,
    iconURL: servicesMedia.servicePhotographyIcon,
    iconHover: servicesMedia.servicePhotographyIconHover,
    iconSvg: servicesMedia.servicePhotographyIcon,
    title: 'PHOTOGRAPHY',
    shortName: 'photography',
    link: '/services',
    active: true,
  },
  {
    mediaHover: servicesMedia.serviceAgentMarketingHover,
    iconURL: servicesMedia.serviceAgentMarketingIcon,
    iconHover: servicesMedia.serviceAgentMarketingIconHover,
    iconSvg: servicesMedia.serviceAgentMarketingIcon,
    title: 'AGENT MARKETING',
    shortName: 'agentMarket',
    link: '/services',
    active: true,
  },
];
export default servicesData;
