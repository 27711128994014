import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import customMedia from '../../utils/customMedia'

const Testimonial = props => {
  const { agentIcon, agentName, content, agentRoles, link } = props

  return (
    <Wrapper>
      <FlexColumn>
        <AgentName>{agentName}</AgentName>
        {agentRoles.map((role, index) => (
          <AgentRole key={`${role}_${index}`}>{role}</AgentRole>
        ))}
        {agentIcon && <Icon src={agentIcon} />}
      </FlexColumn>
      <FlexColumn>
        <Content>{content()}</Content>

        {link && (
          <CaseLink to={link}>
            Read the full case study
            <FontAwesomeIcon
              icon={['fal', 'arrow-right']}
              style={{ marginLeft: 10, fontSize: 14 }}
            />
          </CaseLink>
        )}
      </FlexColumn>
    </Wrapper>
  )
}

Testimonial.propTypes = {
  agentIcon: PropTypes.string,
  agentName: PropTypes.string,
  content: PropTypes.func,
  agentRoles: PropTypes.array,
}

export default Testimonial

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  padding: 20px;

  ${customMedia.greaterThan('mobile')`
    align-items: flex-start;
    flex-direction: row;
  `};
`

const Content = styled.div`
  width: 100%;
  font-family: Open Sans;
  margin-top: 30px;

  ${customMedia.greaterThan('mobile')`
    margin-top: 0;
  `};
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${customMedia.greaterThan('mobile')`
    width: 50%;  
    align-items: flex-end;
    margin-right: 20px;
  `};
`

const Icon = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-top: 10px;
  object-fit: cover;
`

const AgentName = styled.div`
  font-weight: 700;
  margin-top: 30px;

  ${customMedia.greaterThan('mobile')`
    margin-top: 0;
  `};
`
const AgentRole = styled.div`
  font-size: 14px;
  color: #aaaaaa;
  padding: 2px 0;
`

const CaseLink = styled(Link)`
  width: 100%;
  margin-top: 15px;
  color: #02b7e2;
  font-size: 20px;
  font-family: Caveat;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
