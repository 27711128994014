import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import customMedia from '../../utils/customMedia'

const ServiceBox = ({ background, rowItems, icon, title, link, ...other }) => {
  return (
    <Wrapper to={link} {...other}>
      <Overlay>
        <Icon src={icon} />

        <Title>{title}</Title>
      </Overlay>
      <BackgroundImg fluid={background} />
    </Wrapper>
  )
}

ServiceBox.propTypes = {
  background: PropTypes.object,
  icon: PropTypes.string,
  title: PropTypes.string,
}

export default ServiceBox

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 0;
`

const Overlay = styled.div`
  display: block;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f2;
  z-index: 2;
`

const Title = styled.div`
  font-size: 10px;
  color: #4a4a4a;
  letter-spacing: 2px;
  margin-top: 10px;
`

const Wrapper = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
  margin-right: 10px;
  margin-bottom: 10px;
  color: ${props => props.color || '#ffffff'};
  width: calc(100% / 2 - 10px);
  height: 75px;

  ${customMedia.lessThan('mobile')`
    &:nth-child(2n) {
      margin-right: 0;
    };
  `};

  ${customMedia.between('mobile', 'tablet')`
    width: calc(768px / 4 - 30px);
    height: calc(768px / 4 - 30px);

    &:nth-child(4n) {
      margin-right: 0;
    };
  `};

  ${customMedia.greaterThan('desktop')`
    width: calc((100% - 30px) / 4);
    height: 100px;

    &:nth-child(4n) {
        margin-right: 0;
    }

    &:hover {
      cursor: pointer;

      ${Icon} {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(77deg) brightness(101%) contrast(101%);
      };

      ${Title} {
        color: #ffffff;
      }

      ${Overlay} {
        transition: background-color 0.3s;
        background-color: transparent;
      };
    };
  `};
`

const BackgroundImg = styled(Img)`
  width: 100%;
  height: 100%;
`
