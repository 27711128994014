import React from 'react'
import styled from 'styled-components'

import customMedia from '../../utils/customMedia'

const IntroVideo = ({ id = '261069934' }) => (
  <Vimeo>
    <iframe
      src={`https://player.vimeo.com/video/${id}?color=ffffff&byline=0&portrait=0`}
      width="100%"
      height="100%"
      frameBorder="0"
      title="realhub intro video"
      allowFullScreen
    />
  </Vimeo>
)

export default IntroVideo

const Vimeo = styled.div`
  width: 100%;
  height: 250px;

  ${customMedia.between('mobile', 'tablet')`
    height: 350px;
  `};

  ${customMedia.greaterThan('desktop')`
    height: 450px;
  `};
`
