import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import customMedia from '../../utils/customMedia'

const GreyBackground = ({ children, style, skew }) => (
  <Wrapper style={style} skew={skew}>
    <Content skew={skew}>{children}</Content>
  </Wrapper>
)

GreyBackground.propTypes = {
  style: PropTypes.object,
  skew: PropTypes.bool,
}

export default GreyBackground

const Wrapper = styled.div`
  margin: 80px 0;
  padding: 40px 0;
  width: 100%;
  background-color: #f8f8f8;
  transform-origin: ${props => (props.skew ? 'top left' : 'none')};
  transform: ${props => (props.skew ? 'skewY(-8deg)' : 'none')};

  ${customMedia.greaterThan('mobile')`
    transform: ${props => (props.skew ? 'skewY(-4deg)' : 'none')};
  `};
`

const Content = styled.div`
  transform: ${props => (props.skew ? 'skewY(8deg)' : 'none')};

  ${customMedia.greaterThan('mobile')`
    transform: ${props => (props.skew ? 'skewY(4deg)' : 'none')};
  `};
`
