import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import customMedia from '../../utils/customMedia'

class VideoLightBox extends Component {
  state = {
    open: false,
  }

  handleClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    const { open } = this.state
    const { link, text } = this.props

    return (
      <>
        {open ? (
          <Wrapper onClick={this.handleClick}>
            <Vimeo>
              <iframe
                src={`https://player.vimeo.com/video/${link}?color=ffffff&byline=0&portrait=0`}
                width="100%"
                height="100%"
                frameBorder="0"
                title="realhub intro video"
                allowFullScreen
              />
            </Vimeo>
          </Wrapper>
        ) : (
          <VideoLink onClick={this.handleClick}>
            {text + ' '}
            <FontAwesomeIcon
              icon={['fal', 'arrow-right']}
              style={{ fontSize: '12px' }}
            />
          </VideoLink>
        )}
      </>
    )
  }
}

VideoLightBox.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
}

export default VideoLightBox

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
`

const Vimeo = styled.div`
  height: 250px;
  width: 500px;
`

const VideoLink = styled.p`
  font-size: 12px;
  font-weight: 300;
  text-decoration: none;
  color: #02b7e2;
  cursor: pointer;

  ${customMedia.greaterThan('desktop')`
    font-size: 14px;
  `};
`
