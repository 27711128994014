import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import BrandBox from './brandBox'
import Testimonial from './testimonial'

import brandsData from '../../data/brand'

class BrandBoxes extends Component {
  state = {
    content: '',
    brandName: '',
    agentRoles: [],
    agentName: '',
    agentIcon: '',
    brands: [],
    link: null,
  }

  handleBrandChange = index => {
    const brands = brandsData
    const brand = brands[index]
    const copyBrands = JSON.parse(JSON.stringify(brands))
    const { brandName, content, agentRoles, agentName, agentIcon, link } = brand
    copyBrands[index].staging = true

    this.setState({
      content,
      brandName,
      agentRoles,
      agentName,
      agentIcon,
      brands: copyBrands,
      link,
    })
  }

  componentWillMount = () => {
    this.setState({
      brands: brandsData,
    })
    this.handleBrandChange(0)
  }

  render() {
    const {
      brands,
      agentIcon,
      agentName,
      agentRoles,
      content,
      link,
    } = this.state

    return (
      <Fragment>
        <BoxesWrapper>
          {brands.map((brand, index) => (
            <BrandBox
              key={brand.brandName}
              brandIcon={brand.iconSvg}
              staging={brand.staging}
              active={brand.active}
              onClickCallback={() => this.handleBrandChange(index)}
            />
          ))}
        </BoxesWrapper>

        <Testimonial
          agentIcon={agentIcon}
          agentName={agentName}
          agentRoles={agentRoles}
          content={content}
          link={link}
        />
      </Fragment>
    )
  }
}

export default BrandBoxes

const BoxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
