import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import customMedia from '../../utils/customMedia'

const BrandBox = ({ brandIcon, staging, active, onClickCallback }) => (
  <Wrapper onClick={active ? onClickCallback : null} staging={staging}>
    <Icon src={brandIcon} staging={staging} />
  </Wrapper>
)

BrandBox.propTypes = {
  background: PropTypes.string,
  hover: PropTypes.bool,
  iconHover: PropTypes.string,
  iconLength: PropTypes.string,
  iconSize: PropTypes.string,
  iconURL: PropTypes.string,
  mediaHover: PropTypes.string,
  name: PropTypes.string,
}

export default BrandBox

const Icon = styled.img`
  width: 130px;
  height: 85px;
  object-fit: contain;
  margin: 0;

  filter: ${props =>
    props.staging
      ? 'invert(53%) sepia(78%) saturate(1649%) hue-rotate(152deg) brightness(96%) contrast(98%)'
      : 'brightness(0) saturate(100%) invert(77%) sepia(0%) saturate(39%) hue-rotate(86deg) brightness(101%) contrast(100%)'};
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 5px;
  margin-bottom: 5px;
  width: calc(100% / 2 - 5px);
  height: calc(100% / 2 - 5px);
  background-color: ${props => (props.staging ? '#ffffff' : '#f2f2f2')};
  cursor: pointer;

  &:hover {
    background-color: #e4e7e7;

    ${Icon} {
      filter: invert(58%) sepia(6%) saturate(80%) hue-rotate(202deg)
        brightness(96%) contrast(80%);
    }
  }

  ${customMedia.lessThan('mobile')`
    &:nth-child(2n) {
      margin-right: 0;
    };
  `};

  ${customMedia.between('mobile', 'tablet')`
    width: calc((100% - 15px) / 4);
    height: 75px;
    &:nth-child(4n) {
      margin-right: 0;
    };
  `};

  ${customMedia.greaterThan('desktop')`
    width: calc((100% - 15px) / 4);
    height: 75px;
    &:nth-child(4n) {
        margin-right: 0;
    }
  `};
`
