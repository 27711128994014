import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import ServiceBox from './serviceBox'
import servicesData from '../../data/services'
import customMedia from '../../utils/customMedia'

const query = graphql`
  query {
    newspaper: file(relativePath: { eq: "ServiceTiles/hover/Newspaper.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    services: file(relativePath: { eq: "ServiceTiles/hover/Services.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    socialMedia: file(
      relativePath: { eq: "ServiceTiles/hover/SocialMedia.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    video: file(relativePath: { eq: "ServiceTiles/hover/Video.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    web: file(relativePath: { eq: "ServiceTiles/hover/Web.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    print: file(relativePath: { eq: "ServiceTiles/hover/Print.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photography: file(
      relativePath: { eq: "ServiceTiles/hover/Photography.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agentMarket: file(
      relativePath: { eq: "ServiceTiles/hover/AgentMarketing.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const ServiceBoxes = props => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        return (
          <Wrapper>
            {servicesData.map(service => (
              <ServiceBox
                key={service.shortName}
                title={service.title}
                icon={service.iconSvg}
                background={data[service.shortName].childImageSharp.fluid}
                link={service.link}
              />
            ))}
          </Wrapper>
        )
      }}
    />
  )
}

export default ServiceBoxes

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

  ${customMedia.greaterThan('desktop')`
    justify-content: ${props => props.align || 'flex-start'};
  `};
`
